/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, arrayOf, shape, string, bool, number,
} from 'prop-types';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getPassportBundleCollectionData } from '../../../../../../../../../state/ducks/Common/Common-Selectors';
import { getFeatureFlags } from '../../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTests } from '../../../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import urlPromoHelper from '../../../../../../../../helpers/urlPromoHelper/urlPromoHelper';
import SkeletonStyles from '../../../../../../GraphqlSkeletonComponents/SkeletonStyles';

import UrlPromoBanner from './UrlPromoBanner';

const VIEWPORT = {
    MOBILE: 'Mobile',
    DESKTOP: 'Desktop',
};

const styles = (theme) => ({
    ...SkeletonStyles,
    crossedOutRetailPrice: {
        textDecoration: 'line-through',
        fontWeight: 400,
    },
    crossedOutRetailPriceOneClick: {
        textDecoration: 'line-through',
        color: '#000',
        // fontWeight: 700,
    },
    salePrice: {
        color: theme.palette.pdp?.salePrice || '#a1001a',
    },
    salePriceOneClick: {
        color: '#A1001A',
    },
    productName: {
        fontWeight: '400',
    },
    wrappedPrice: {
        display: 'inline-block',
    },
    titleColor: {
        color: '#2F2F2F',
    },
    mobileCenteredPrice: {
        display: 'block',
        marginTop: '5px',
        color: theme.palette.product?.retailPrice || theme.palette.cms?.nonSaleItemPrice || theme.palette.cms?.primary || theme.palette.colorPrimary,
    },
    abTestPriceDesignFood: {
        marginBottom: '10px',
        fontSize: '16px',
        lineHeight: '20px',
        fontFamily: 'LatoBold',
        letterSpacing: '0.02em',
    },
    abTestPriceDesignFloral: {
        fontSize: '15px',
        lineHeight: '23px',
        fontFamily: 'LatoMedium',
    },
    abTestCrossedOut: {
        textDecoration: 'line-through',
        color: theme.palette.product?.salePrice || theme.palette.primaryButton,
    },
    abTestCrossedOutRetailPrice: {
        color: '#2F2F2F',
    },
    pullLeft: {
        display: 'block',
        textAlign: 'left',
    },
});

const getPrices = (skuPriceRange, type, passportBundleCollectionData, isFoodBrand, forceItemPrice = false) => {
    if (!skuPriceRange) return {};
    const priceData = skuPriceRange[type];
    const startingDisplayPrice = priceData?.[0]?.display ? Number(priceData?.[0]?.display).toFixed(2) : '';
    const endingDisplayPrice = priceData?.[priceData.length - 1]?.display ? Number(priceData?.[priceData.length - 1]?.display).toFixed(2) : '';
    if (forceItemPrice) {
        if (passportBundleCollectionData?.enabled && passportBundleCollectionData?.price) {
            return {
                startingDisplayPrice,
                endingDisplayPrice,
                startingPrice: Number(priceData?.[0]?.value + passportBundleCollectionData.price).toFixed(2),
                endingPrice: Number(priceData?.[priceData.length - 1]?.value + passportBundleCollectionData.price).toFixed(2),
            };
        }
        return {
            startingDisplayPrice,
            endingDisplayPrice,
            startingPrice: isFoodBrand ? priceData?.[0]?.value : Number(priceData?.[0]?.value).toFixed(2),
            endingPrice: isFoodBrand ? priceData?.[priceData.length - 1]?.value : Number(priceData?.[priceData.length - 1]?.value).toFixed(2),
        };
    }
    if (passportBundleCollectionData?.enabled && passportBundleCollectionData?.price) {
        return {
            startingDisplayPrice,
            endingDisplayPrice,
            startingPrice: Number((priceData?.[0]?.total || priceData?.[0]?.value) + passportBundleCollectionData.price).toFixed(2),
            endingPrice: (Number(priceData?.[priceData.length - 1]?.total || priceData?.[priceData.length - 1]?.value) + passportBundleCollectionData.price).toFixed(2),
        };
    }
    return {
        startingDisplayPrice,
        endingDisplayPrice,
        startingPrice: isFoodBrand ? priceData?.[0]?.total || priceData?.[0]?.value : Number(priceData?.[0]?.total || priceData?.[0]?.value).toFixed(2),
        endingPrice: isFoodBrand ? priceData?.[priceData.length - 1]?.total || priceData?.[priceData.length - 1]?.value : Number(priceData?.[priceData.length - 1]?.total || priceData?.[priceData.length - 1]?.value).toFixed(2),
    };
};

const PriceRange = ({
    priceRangeLayout,
    skuPriceRange,
    isMobile,
    classes,
    presentationFamily,
    passportBundleCollectionData,
    activeABTests,
    location,
    featureFlags,
    filterOptions,
    displayPrice,
    showSimpleProductRedesignAbTest,
    isCombiningPrices,
    priceForCombination,
    colorMatchTitle,
    noFromText,
    collectionRestructureEnabled,
    forceItemPriceOnly,
}) => {
    const viewport = isMobile ? VIEWPORT.MOBILE : VIEWPORT.DESKTOP;
    const isSSR = typeof window === 'undefined';
    const priceLayout = (priceRangeLayout || []).find((layout) => layout.viewport === viewport) || {};
    let { price_layout = '' } = priceLayout;
    const isFoodBrand = presentationFamily === 'food';

    // Default Layout type
    // All desktop views and food brands are using this format, if not defined in contentstack this will be used
    if (price_layout === '') {
        if (!collectionRestructureEnabled && (isFoodBrand || !isMobile)) {
            price_layout = '$xxx.xx - $xxx.xx';
        }
        if (activeABTests?.category_price_display === 'from' || collectionRestructureEnabled) {
            // allows for optimize test logic override
            price_layout = 'from $xxx.xx';
        }
        if (activeABTests?.category_price_display === 'lowest') {
            price_layout = '$xxx.xx';
        }
        // else use default case in switch
    }

    let {
        startingPrice: saleStarting = '',
        endingPrice: saleEnding = '',
    } = (getPrices(skuPriceRange, 'sale', passportBundleCollectionData, isFoodBrand, forceItemPriceOnly));
    const {
        startingDisplayPrice: saleStartingDisplayPrice = '',
    } = (getPrices(skuPriceRange, 'sale', passportBundleCollectionData, isFoodBrand, forceItemPriceOnly));

    let {
        startingPrice: retailStarting = '',
        endingPrice: retailEnding = '',
    } = (getPrices(skuPriceRange, 'retail', passportBundleCollectionData, isFoodBrand, forceItemPriceOnly));
    const {
        endingDisplayPrice: retailEndingDisplayPrice = '',
        startingDisplayPrice: retailStartingDisplayPrice = '',
    } = (getPrices(skuPriceRange, 'retail', passportBundleCollectionData, isFoodBrand, forceItemPriceOnly));

    if (retailStartingDisplayPrice && retailStarting !== retailStartingDisplayPrice) retailStarting = retailStartingDisplayPrice;
    if (retailEndingDisplayPrice && retailEnding !== retailEndingDisplayPrice) retailEnding = retailEndingDisplayPrice;

    const isPassportBundleCollection = passportBundleCollectionData?.enabled && passportBundleCollectionData?.price;
    let isSale = Number(saleStarting) < Number(retailStarting) || Number(saleEnding) < Number(retailEnding);

    if (!isSale && saleStartingDisplayPrice && saleStartingDisplayPrice !== 'NaN' && retailEndingDisplayPrice && retailEndingDisplayPrice !== 'NaN' && !noFromText) {
        isSale = saleStarting.toString() !== saleStartingDisplayPrice.toString() || retailEnding.toString() !== retailEndingDisplayPrice.toString();
    }
    let displayPromoBanner = false;

    let salePriceText = '';
    let retailPriceText = '';

    const windowLocation = location?.search ?? '';
    const urlPromoPackage = urlPromoHelper.parser(windowLocation);

    /**
     * URL promo logic should be DISABLED when:
     * - The product is already on sale.
     * - the product is apart of Passport Bundle.
     */
    if (!isSale && !isPassportBundleCollection && featureFlags['is-url-promo-enabled'] && urlPromoPackage.hasPromo) {
        /**
         * The current URL promo offerings have 2 scenarios:
         *  1. There is a monetary discount which should display the banner and the discount as the sale price.
         *  2. Free shipping which doesnt affect the price at all but should show banner.
         */

        // show banner
        displayPromoBanner = true;

        // if its not free shipping, calculate the new prices with discounts
        if (urlPromoPackage.type !== 'f') {
            // enable sale display for promo pricing
            isSale = true;
            // calc the new sale price using retail prices
            saleStarting = urlPromoHelper.calcPrice(urlPromoPackage, retailStarting);
            saleEnding = urlPromoHelper.calcPrice(urlPromoPackage, retailEnding);
        }
    }

    const filterOptionsDisplay = (filters = {}, start, end) => {
        const startPrice = start && parseFloat(start).toFixed(2);
        const endPrice = end && parseFloat(end).toFixed(2);
        if (startPrice || endPrice) {
            if (filters?.highToLowBtn) {
            // show ending/highest price
                return `$${endPrice}`;
            }
            // show starting/lowest price
            return `$${startPrice}`;
        }
        return '';
    };

    if (displayPrice === false || isSSR) {
        return (
            <div className={`${classes.textLG} ${classes.shimmer}`} data-testid="sale-price">
                {' '}
                {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />}
            </div>
        );
    }

    if (isCombiningPrices) {
        const {
            startingPrice: saleStarting2 = '',
            // endingPrice: saleEnding2 = '',
        } = getPrices(priceForCombination, 'sale', passportBundleCollectionData, isFoodBrand, forceItemPriceOnly);
        const {
            startingPrice: retailStarting2 = '',
            // endingPrice: retailEnding2 = '',
        } = getPrices(priceForCombination, 'retail', passportBundleCollectionData, isFoodBrand, forceItemPriceOnly);

        const comboRetail2 = (parseFloat(retailStarting2) + parseFloat(retailStarting)).toFixed(2);
        const comboSale2 = (parseFloat(saleStarting2) + parseFloat(saleStarting)).toFixed(2);

        return (
            <span data-testid="combined-price">
                <span className={classes.productName} />
                <span>
                    {isSale ? `$${comboSale2}` : `$${comboRetail2}`}
                </span>
                {/* {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />} */}
            </span>
        );
    }

    switch (price_layout) {
        // Used by food & flowers desktop
        case '$xxx.xx - $xxx.xx':
            retailPriceText = retailStarting !== retailEnding ? `$${retailStarting} - $${retailEnding}` : filterOptionsDisplay(filterOptions, retailStarting, retailEnding);
            salePriceText = saleStarting !== saleEnding ? `$${saleStarting} - $${saleEnding}` : filterOptionsDisplay(filterOptions, saleStarting, saleEnding);
            // ABTEST
            if (activeABTests?.mobile_simple_product_redesign && showSimpleProductRedesignAbTest) {
                if (isSale && retailPriceText !== salePriceText) {
                    return (
                        <span data-testid="sale-price" className={`${isMobile && classes.abTestPriceDesignFood}`}>
                            <span className={classes.abTestCrossedOut}>
                                <s className={`${classes.abTestCrossedOutRetailPrice} crossed-price`}>
                                    {retailPriceText}
                                </s>
                            </span>
                            {' '}
                            <span className={`${classes.salePrice} ${classes.wrappedPrice} sale-price`}>
                                {`${priceLayout.sale_text ? `${priceLayout.sale_text} ` : ''}${salePriceText}`}
                            </span>
                        </span>

                    );
                }
                return (
                    <span data-testid="retail-price-range" className={`${isMobile && classes.abTestPriceDesignFood}`}>
                        {retailPriceText}
                    </span>
                );
            }

            if (isSale && retailPriceText !== salePriceText) {
                return (
                    <span className={isMobile ? classes.mobileCenteredPrice : ''} data-testid="sale-price">
                        <s className={`${classes.wrappedPrice} crossed-price`}>
                            {retailPriceText}
                        </s>
                        {' '}
                        <span className={`${classes.salePrice} ${classes.wrappedPrice} sale-price`}>
                            {`${priceLayout.sale_text ? `${priceLayout.sale_text} ` : ''}${salePriceText}`}
                        </span>
                        {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />}
                    </span>

                );
            }
            return (
                <span data-testid="retail-price-range">
                    <span className={`${colorMatchTitle ? classes.titleColor : ''}  ${isMobile ? classes.mobileCenteredPrice : ''}`}>
                        {retailPriceText}
                    </span>
                    {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />}
                </span>
            );

        // Used by 18F
        default:
        case 'from $xxx.xx':
            retailPriceText = filterOptionsDisplay(filterOptions, retailStarting, retailEnding);
            salePriceText = filterOptionsDisplay(filterOptions, saleStarting, saleEnding);
            if (activeABTests?.mobile_simple_product_redesign && showSimpleProductRedesignAbTest) {
                if (isSale && retailPriceText !== salePriceText) {
                    return (
                        <span data-testid="sale-price" className={classes.abTestPriceDesignFloral}>
                            <span>
                                {'from '}
                                <strong>
                                    <span className={classes.abTestCrossedOut}>
                                        <span className={`${classes.abTestCrossedOutRetailPrice} crossed-price`}>
                                            {retailPriceText}
                                        </span>
                                    </span>
                                    {/* <span className={classes.abTestCrossedOutPrice}>
                                        {retailPriceText}
                                    </span> */}
                                </strong>
                            </span>
                            {' '}
                            <span className={`${classes.salePrice}`}>
                                <strong>
                                    {`${priceLayout.sale_text ? `${priceLayout.sale_text} ` : ''}${salePriceText}`}
                                </strong>
                            </span>
                            {/* {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />} */}
                        </span>
                    );
                }
                return (
                    <span data-testid="retail-price-range" className={classes.abTestPriceDesignFloral}>
                        <span>
                            {'from '}
                        </span>
                        <span>
                            <strong>
                                {retailPriceText}
                            </strong>
                        </span>
                        {/* {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />} */}
                    </span>
                );
            }
            if (isSale && retailPriceText !== salePriceText) {
                return (
                    <span className={noFromText ? classes.pullLeft : ''} data-testid="sale-price">
                        <span className={classes.productName}>
                            {!noFromText && 'from '}
                            <s>
                                {retailPriceText?.toString?.() !== salePriceText?.toString?.() ? retailPriceText : retailStartingDisplayPrice}
                            </s>
                        </span>
                        {' '}
                        <span className={`${classes.salePrice}`}>
                            {`${priceLayout.sale_text ? `${priceLayout.sale_text} ` : ''}${salePriceText}`}
                        </span>
                        {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />}
                    </span>
                );
            }
            return (
                <span className={noFromText ? classes.pullLeft : ''} data-testid="retail-price-range">
                    {!noFromText && (
                        <span className={classes.productName}>
                            {'from '}
                        </span>
                    )}
                    <span>
                        {retailPriceText}
                    </span>
                    {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />}
                </span>
            );
        case '$xxx.xx':
            retailPriceText = filterOptionsDisplay(filterOptions, retailStarting, retailEnding);
            salePriceText = filterOptionsDisplay(filterOptions, saleStarting, saleEnding);
            if (isSale && retailPriceText !== salePriceText) {
                return (
                    <span data-testid="sale-price">
                        <span className={classes.productName}>
                            <s>
                                {retailPriceText}
                            </s>
                        </span>
                        {' '}
                        <span className={`${classes.salePrice} sale-price`}>
                            {`${priceLayout.sale_text ? `${priceLayout.sale_text} ` : ''}${salePriceText}`}
                        </span>
                        {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />}
                    </span>
                );
            }
            return (
                <span data-testid="retail-price-range">
                    <span className={classes.productName} />
                    <span>
                        {retailPriceText}
                    </span>
                    {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />}
                </span>
            );
        case 'one-click':
            retailPriceText = filterOptionsDisplay(filterOptions, retailStarting, retailEnding);
            salePriceText = filterOptionsDisplay(filterOptions, saleStarting, saleEnding);
            if (isSale && retailPriceText !== salePriceText) {
                return (
                    <span data-testid="sale-price-oc">
                        <span className={`${classes.salePriceOneClick} sale-price`}>
                            {`${priceLayout.sale_text ? `${priceLayout.sale_text} ` : ''}${salePriceText}`}
                        </span>
                        {' '}
                        <span className={classes.productName}>
                            <span id="crossedOutPrice" className={`${classes.crossedOutRetailPriceOneClick} crossed-price`}>
                                {retailPriceText}
                            </span>
                        </span>
                        {/* {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />} */}
                    </span>
                );
            }
            return (
                <span id="rprrpr" data-testid="retail-price-range">
                    <span className={classes.productName} />
                    <span>
                        {retailPriceText}
                    </span>
                    {displayPromoBanner && <UrlPromoBanner urlPromoPackage={urlPromoPackage} />}
                </span>
            );
    }
};

PriceRange.propTypes = {
    featureFlags: object.isRequired,
    presentationFamily: string.isRequired,
    priceRangeLayout: arrayOf(shape({
        price_layout: string,
        sale_text: string,
        viewport: string,
    })),
    skuPriceRange: shape({
        retail: arrayOf(shape({
            value: number,
        })),
        sale: arrayOf(shape({
            value: number,
        })),
    }),
    isMobile: bool,
    classes: object.isRequired,
    passportBundleCollectionData: shape({
        enabled: bool,
        price: number,
    }),
    activeABTests: object,
    location: shape({
        search: string,
    }),
    filterOptions: object,
    displayPrice: bool.isRequired,
    showSimpleProductRedesignAbTest: bool.isRequired,
    isCombiningPrices: bool,
    priceForCombination: shape({
        retail: arrayOf(shape({
            value: number,
        })),
        sale: arrayOf(shape({
            value: number,
        })),
    }),
    colorMatchTitle: bool,
    noFromText: bool,
    collectionRestructureEnabled: bool,
    forceItemPriceOnly: bool,
};

PriceRange.defaultProps = {
    priceRangeLayout: [
        {
            price_layout: '',
            sale_text: '',
            viewport: VIEWPORT.DESKTOP,
        },
        {
            price_layout: '',
            sale_text: '',
            viewport: VIEWPORT.MOBILE,
        },
    ],
    skuPriceRange: {},
    isMobile: false,
    passportBundleCollectionData: {},
    activeABTests: {},
    location: {
        search: '',
    },
    filterOptions: {},
    isCombiningPrices: false,
    priceForCombination: {},
    colorMatchTitle: false,
    noFromText: false,
    collectionRestructureEnabled: false,
    forceItemPriceOnly: false,
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    passportBundleCollectionData: getPassportBundleCollectionData(state),
    activeABTests: getActiveABTests(state),
});

export default connect(mapStateToProps)(withStyles(styles)(PriceRange));
